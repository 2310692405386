import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, limit, startAfter, updateDoc, doc, arrayUnion, getDoc, where } from 'firebase/firestore';
import { db, auth } from '../firebase';
import VoteModal from './VoteModal';
import { Link } from 'react-router-dom';
import { MessageSquare, Share2, Search } from 'react-feather';
import './PublicFeed.css';

const BETS_PER_PAGE = 10;

function PublicFeed({ user }) {
  const [bets, setBets] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [selectedBet, setSelectedBet] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [votedBetIds, setVotedBetIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    fetchBets();
  }, [user]);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (searchQuery) {
        performSearch();
      } else {
        setSearchResults([]);
        setIsSearching(false);
      }
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  const performSearch = async () => {
    setIsSearching(true);
    setLoading(true);
    try {
      // Get all bets first (you might want to limit this in production)
      const q = query(collection(db, 'publicBets'), limit(100));
      const querySnapshot = await getDocs(q);
      
      // Get all documents and perform client-side filtering
      const allBets = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Perform case-insensitive search on both title and description
      const searchTerm = searchQuery.toLowerCase();
      const searchedBets = allBets.filter(bet => {
        const titleMatch = bet.title?.toLowerCase().includes(searchTerm);
        const descriptionMatch = bet.description?.toLowerCase().includes(searchTerm);
        return titleMatch || descriptionMatch;
      });

      // Highlight matching text (add a highlightedTitle and highlightedDescription property)
      const betsWithHighlights = searchedBets.map(bet => {
        const highlightText = (text) => {
          if (!text) return '';
          const regex = new RegExp(`(${searchTerm})`, 'gi');
          return text.replace(regex, '<mark>$1</mark>');
        };

        return {
          ...bet,
          highlightedTitle: highlightText(bet.title),
          highlightedDescription: highlightText(bet.description)
        };
      });

      setSearchResults(betsWithHighlights);
    } catch (err) {
      console.error("Error searching bets:", err);
      setError("Failed to search bets. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const fetchBets = async () => {
    setLoading(true);
    setError(null);
    try {
      let fetchedBets = [];
      let lastVisibleDoc = null;
      
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        const userVotedBetIds = userDoc.data()?.voteHistory?.map(vote => vote.betId) || [];
        setVotedBetIds(userVotedBetIds);

        let q = query(collection(db, 'publicBets'), limit(BETS_PER_PAGE));
        let querySnapshot = await getDocs(q);

        fetchedBets = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(bet => !userVotedBetIds.includes(bet.id));

        while (fetchedBets.length < BETS_PER_PAGE && querySnapshot.docs.length === BETS_PER_PAGE) {
          lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
          q = query(collection(db, 'publicBets'), startAfter(lastVisibleDoc), limit(BETS_PER_PAGE));
          querySnapshot = await getDocs(q);
          
          const moreBets = querySnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(bet => !userVotedBetIds.includes(bet.id));
          
          fetchedBets = [...fetchedBets, ...moreBets].slice(0, BETS_PER_PAGE);
        }

        lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      } else {
        const q = query(collection(db, 'publicBets'), limit(BETS_PER_PAGE));
        const querySnapshot = await getDocs(q);
        fetchedBets = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      }

      // Seed upvotes if not present or NaN
      fetchedBets = await Promise.all(fetchedBets.map(async (bet) => {
        if (!bet.upvotes || isNaN(bet.upvotes)) {
          const initialUpvotes = Math.floor(Math.random() * (200 - 50 + 1)) + 50;
          await updateDoc(doc(db, 'publicBets', bet.id), { upvotes: initialUpvotes });
          return { ...bet, upvotes: initialUpvotes };
        }
        return bet;
      }));

      setBets(fetchedBets);
      setLastVisible(lastVisibleDoc);
    } catch (err) {
      console.error("Error fetching bets:", err);
      setError("Failed to load bets. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleVote = (bet) => {
    setSelectedBet(bet);
    setShowModal(true);
  };

  const confirmVote = async (vote) => {
    if (!user) {
      alert('Please sign in to vote');
      return;
    }

    try {
      const betRef = doc(db, 'publicBets', selectedBet.id);
      const userRef = doc(db, 'users', user.uid);

      await updateDoc(betRef, {
        [`votes.${vote}`]: selectedBet.votes[vote] + 1,
        [`voters.${vote}`]: arrayUnion(user.uid)
      });

      await updateDoc(userRef, {
        voteHistory: arrayUnion({
          betId: selectedBet.id,
          vote: vote,
          timestamp: new Date()
        }),
        score: user.score + 1 // Increase user's score
      });

      setShowModal(false);
      setVotedBetIds([...votedBetIds, selectedBet.id]);
      fetchBets(); // Refresh the bets after voting
    } catch (err) {
      console.error("Error confirming vote:", err);
      alert("Failed to submit your vote. Please try again.");
    }
  };

  const fetchMoreBets = async () => {
    if (!lastVisible) return;
    
    setLoading(true);
    try {
      let fetchedBets = [];
      let lastVisibleDoc = lastVisible;
      
      while (fetchedBets.length < BETS_PER_PAGE) {
        const q = query(collection(db, 'publicBets'), startAfter(lastVisibleDoc), limit(BETS_PER_PAGE));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) break;
        
        const moreBets = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(bet => !votedBetIds.includes(bet.id));
        
        fetchedBets = [...fetchedBets, ...moreBets];
        lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        
        if (querySnapshot.docs.length < BETS_PER_PAGE) break;
      }

      setBets(prevBets => [...prevBets, ...fetchedBets.slice(0, BETS_PER_PAGE)]);
      setLastVisible(lastVisibleDoc);
      setCurrentPage(prevPage => prevPage + 1);
    } catch (err) {
      console.error("Error fetching more bets:", err);
      setError("Failed to load more bets. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpvote = async (betId) => {
    if (!user) {
      alert('Please sign in to upvote');
      return;
    }
    try {
      const betRef = doc(db, 'publicBets', betId);
      const betDoc = await getDoc(betRef);
      const currentUpvotes = betDoc.data().upvotes || 0;
      await updateDoc(betRef, {
        upvotes: currentUpvotes + 1,
        downvotes: betDoc.data().downvotes || []
      });
      fetchBets();
    } catch (error) {
      console.error("Error upvoting:", error);
    }
  };

  const handleDownvote = async (betId) => {
    if (!user) {
      alert('Please sign in to downvote');
      return;
    }
    try {
      const betRef = doc(db, 'publicBets', betId);
      const betDoc = await getDoc(betRef);
      const currentDownvotes = betDoc.data().downvotes || 0;
      await updateDoc(betRef, {
        downvotes: currentDownvotes + 1,
        upvotes: betDoc.data().upvotes || []
      });
      fetchBets();
    } catch (error) {
      console.error("Error downvoting:", error);
    }
  };

  const handleShare = (bet) => {
    if (navigator.share) {
      navigator.share({
        title: bet.title,
        text: bet.description,
        url: `${window.location.origin}/bet/${bet.id}`
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      alert(`Share this link: ${window.location.origin}/bet/${bet.id}`);
    }
  };

  const renderBets = (betsToRender) => (
    <div>
      {betsToRender.map(bet => (
        <div key={bet.id} className="bet-card">
          <div className="bet-card-content">
            <div className="vote-column">
              <button onClick={() => handleUpvote(bet.id)} className="vote-button upvote">
                {/* Upvote arrow is now created using CSS */}
              </button>
              <span className="vote-count">
                {bet.upvotes - (bet.downvotes || 0)}
              </span>
              <button onClick={() => handleDownvote(bet.id)} className="vote-button downvote">
                {/* Downvote arrow is now created using CSS */}
              </button>
            </div>
            <div className="content-column">
              <Link to={`/bet/${bet.id}`} className="bet-link">
                <h3 className="bet-title" 
                    dangerouslySetInnerHTML={{ 
                      __html: isSearching ? bet.highlightedTitle : bet.title 
                    }} 
                />
                <p className="bet-description"
                   dangerouslySetInnerHTML={{ 
                     __html: isSearching ? bet.highlightedDescription : bet.description 
                   }} 
                />
                {bet.expirationDate && (
                  <p className="expiration-date">Expires: {new Date(bet.expirationDate.seconds * 1000).toLocaleDateString()}</p>
                )}
              </Link>
              <div className="action-buttons-container">
                <button className="action-button vote-yes" onClick={() => handleVote(bet)}>Yes</button>
                <button className="action-button vote-no" onClick={() => handleVote(bet)}>No</button>
                <Link to={`/bet/${bet.id}`} className="link-button">
                  <MessageSquare size={16} style={{ marginRight: '5px' }} />
                  <span>Comment</span>
                </Link>
                <button onClick={() => handleShare(bet)} className="link-button share-button">
                  <Share2 size={16} style={{ marginRight: '5px' }} />
                  <span>Share</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  if (loading && bets.length === 0) {
    return <div className="loading">Loading bets...</div>;
  }

  if (error) {
    return <div className="container">{error}</div>;
  }

  return (
    <div className="container public-feed">
      <div className="search-container">
        <div className="search-input-wrapper">
          <Search size={20} className="search-icon" />
          <input
            type="text"
            placeholder="Search bets..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>
      </div>
      
      <h2 className="title">Public Bets</h2>
      
      {isSearching ? (
        searchResults.length === 0 ? (
          <p>No results found for "{searchQuery}"</p>
        ) : (
          renderBets(searchResults)
        )
      ) : bets.length === 0 ? (
        <p>No more bets available at the moment.</p>
      ) : (
        renderBets(bets)
      )}

      {!isSearching && lastVisible && bets.length >= BETS_PER_PAGE && (
        <button onClick={fetchMoreBets} className="load-more-button" disabled={loading}>
          {loading ? 'Loading...' : 'Load More'}
        </button>
      )}
      
      <div className="pagination">
        Page {currentPage}
      </div>
      
      {showModal && (
        <VoteModal
          bet={selectedBet}
          onConfirm={confirmVote}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default PublicFeed;